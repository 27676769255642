<template>
  <div class="login-wrap">
     <div class="login-main">
       <div class="login-fc-log">
          <img class="fc-log-image" src="@/assets/images/fc-login-whith.png" alt="" />
       </div>
       <div class="login-logo-culture">
       </div>
      <div class="login-box">
        <div class="title">
          IOT平台
        </div>
        <el-form
          ref="loginForm"
          class="login-form"
          :model="form"
          label-position="top"
          :hide-required-asterisk="true"
          :rules="rules">
          <el-form-item prop="account" label="账号" class="is-success">
            <el-row :gutter="10" type="flex" justify="space-between" align="middle">
              <el-col :span="7">
                <el-select data-select style="width: 100%" v-model="areaNo" placeholder="请选择">
                  <template slot="prefix"><el-image class="select-flag" :src="selectedArea.imgUrl"/></template>
                  <el-option v-for="area in areaList" :key="area.value" :label="`+${area.value}`" :value="area.value">
                    <el-image class="select-flag" :src="area.imgUrl"></el-image>{{ area.label }}
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="17">
                <el-input
                  clearable
                  name="account"
                  type="text"
                  v-model="form.account"
                  placeholder="请输入手机号"
                >
                </el-input>
              </el-col>
            </el-row>
            <template #error="{ error }">
              <span class="el-form-item__error" style="margin-left: 125px">{{ error }}</span>
            </template>
          </el-form-item>
          <el-form-item label="密码" prop="password" >
            <el-input
              clearable
              type="password"
              show-password
              placeholder="请输入密码"
              v-model="form.password"
            />
          </el-form-item>
          <el-button
            type="success"
            :loading="loading"
            size="large"
            @click="handleLogin"
          >
            登录
          </el-button>
        </el-form>
      </div>
    </div>
    <!-- 首次修改密码 -->
     <ChangePassword
      :visiblepass.sync="visiblepass"
      :first-login="firstLogin">
    </ChangePassword>
  </div>
</template>

<script>
import { exJSEncrypt } from '@/utils/rsa'
import { mapActions } from 'vuex'
import ChangePassword from '../components/change-password'

import areas from '@/utils/areas'

export default {
  name: 'Login',
  components: {
    ChangePassword
  },
  data() {
    return {
      areaNo: '86',
      areaList: areas,
      form: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          { required: true, message: '请填写电话号码' },
          { pattern: /^[0-9]{1,20}$/, message: '请输入1-20位数字' }
        ],
        password: [{ required: true, message: '请填写登录密码' }],
      },
      loading: false,
      visiblepass: false,
      user: {}
    }
  },
  mounted() {
    this.getPublicKey()
  },
  computed: {
    firstLogin() {
      return this.user.firstLogin === 1
    },
    selectedArea() {
      return this.areaList.filter(i => i.value === this.areaNo)[0]
    },
  },
  methods: {
    ...mapActions(['login']),
    ...mapActions(['getPublicKey']),
    handleLogin() {
      console.log(this.form)
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return
        const code = await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new, new-cap, no-undef
          new fcSliderCaptcha({
            apiPathCustom: process.env.NODE_ENV === 'development' ? '/development' : '',
            apiQuery: {
              'Content-Type': 'text/xml',
              headers: {
                'Accept-Language': 'zh-CN',
              },
            },
            apiCheck: {
              'Content-Type': 'text/xml',
              headers: {
                'Accept-Language': 'zh-CN',
              },
            },
            success: (r) => {
              resolve(r.token)
            },
          })
        })
        this.loading = true
        const password = exJSEncrypt(this.$store.state.publicKey, this.form.password).data
        const payload = {
          password: password,
          code,
          account: this.form.account,
          areaNo: this.areaNo
        }
        this.login(payload)
          .then(async(res) => {
            // const codeList = ['103000002', '001000001', '001000002', '000000004', '103000006']
            if (res.code === '000000000') {
              // 如首次登录弹出弹窗让用户修改密码
              const userList = this.user = this.$store.getters.userInformation
              this.user = userList
              // console.log('userList: ', userList)
              if (userList.firstLogin && userList.firstLogin === 1) {
                this.$confirm('首次登录请及时修改密码').then(() => {
                  this.visiblepass = true
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消'
                  })
                })
              } else {
                this.$message.success('登录成功')
                const routes = this.$router.options.routes.filter(item => item.children) || []
                routes.length ? this.$router.replace(routes[2].path) : this.$router.replace('/')
              }
            } else {
              console.log('res.msg: ', res.msg)
              this.loading = false
              this.$message.warning(res.msg)
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap{
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("~@/assets/images/fc-login-bg.png") no-repeat center 0px;
  background-position:center 0px ;
  background-size:cover ;
  text-indent: -1;
}
.login-main {
  // position: relative;
  .login-fc-log{
    position: absolute;
    top: 40px;
    left: 48px;
    .fc-log-image{
      width: 80px;
      height: 34px;
    }
  }
  .login-logo-culture{
    position: absolute;
    top: 50%;
    left: 74px;
    transform: translate(0,-50%);
    width: 643px;
    height: 518px;
    background: url('~@/assets/images/logo-culture.png') no-repeat;
  }
  .login-box {
    position: absolute;
    width: 496px;
    max-width: 100vw;
    // height: 624px;
    background: #ffffff;
    border-radius: 8px;
    border: 2px solid #f2f3f5;
    right: 17vw;
    top: 50%;
    transform: translate(0%, -50%);
    padding: 48px;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
    .title {
      font-size: 32px;
      font-weight: 400;
      color: #000000;
      line-height: 60px;
      letter-spacing: 0.05em;
    }
    .el-button {
      width: 100%;
      margin: 44px 0 0 0;
    }
  }
}
 // 去除记住密码背景颜色
  ::v-deep input:-webkit-autofill,textarea:-webkit-autofill,select:-webkit-autofill {
    -webkit-text-fill-color: #323233 !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
  }
  ::v-deep input {
    background-color: transparent;
  }
</style>
<style lang="scss">
.login-form {
  width: 100%;
    margin-top: 46px;
  & .el-form-item {
    margin-bottom: 24px;
    & .el-input--suffix .el-input__inner {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      padding: 0;
      font-size: 16px;
      height: 48px;
    }
    & .el-select[data-select] .el-input--suffix .el-input__inner {
      border-color: rgb(220, 222, 224);
      padding-left: 35px;
    }
  }
}
// 选择国家-国旗
.select-flag {
  width: 20px;
  height: 100%;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  img {
    height: 14px;
  }
}
</style>
