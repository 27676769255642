/*
 * @Author: 秦龙(004894)
 * @Date: 2022-08-10 11:19:34
 * @LastEditTime: 2022-09-22 18:39:34
 * @LastEditors: 秦龙(004894)
 * @Description: 国家/地区
 * @FilePath: /i18n-saas-iot-frontend/src/utils/areas.js
 */
export default [
  {
    label: '中国',
    value: '86',
    imgUrl: require('@/assets/flags/china.png')
  },
  {
    label: '新加坡',
    value: '65',
    imgUrl: require('@/assets/flags/singapore.png')
  },
  {
    label: '澳大利亚',
    value: '61',
    imgUrl: require('@/assets/flags/australia.png')
  },
  {
    label: '泰国',
    value: '66',
    imgUrl: require('@/assets/flags/thailand.png')
  }
]
